import React from "react"

function CoverVertical() {
  return (
    <g id="Cover-Vert" serifId="Cover Vert">
      <path
        d="M360,97.143L360,984.857"
        style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
      />
      <g transform="matrix(1,0,0,1,80,0)">
        <path
          d="M360,97.143L360,984.857"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,160,-1.7053e-13)">
        <path
          d="M360,97.143L360,984.857"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,240,-2.27374e-13)">
        <path
          d="M360,97.143L360,984.857"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,320,-2.84217e-13)">
        <path
          d="M360,97.143L360,984.857"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,400,0)">
        <path
          d="M360,97.143L360,984.857"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
    </g>
  )
}

export default CoverVertical
