import React from "react"

function Hidden() {
  return (
    <g>
      <g transform="matrix(0.645833,0,0,1.8,191.25,-40)">
        <rect
          x="300"
          y="50"
          width="480"
          height="50"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "1.48px",
          }}
        />
      </g>
      <g transform="matrix(1,0,0,1,65,0)">
        <text
          x="371.284px"
          y="92.249px"
          style={{ fontFamily: "'Consolas', monospace", fontSize: "45px" }}
        >
          С
          <tspan
            x="401.274px 431.265px 461.255px 491.245px 521.235px 551.226px "
            y="92.249px 92.249px 92.249px 92.249px 92.249px 92.249px "
          >
            КРЫТОЕ
          </tspan>
        </text>
      </g>
    </g>
  )
}

export default Hidden
