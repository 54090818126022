import React from "react"

function Cover45() {
  return (
    <g id="Шпон" transform="matrix(1.61156,0,0,1.17647,-290.602,-377.647)">
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.818,212.182L402.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.818,312.182L502.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.643,412.357L602.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.643,512.357L702.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.643,612.357L802.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.818,712.182L832.123,167.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.643,812.357L832.123,267.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.818,912.182L832.123,367.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M315,985L832.123,467.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M415,985L832.123,567.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M515,985L832.123,667.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M615,985L832.123,767.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M715,985L832.123,867.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
    </g>
  )
}

export default Cover45
