import React from "react"
import AASh from "../Forms/2D/Sh/AASh"

function CardSh({ item }) {
  return (
    <div className="h-56">
      <AASh item={item} />
    </div>
  )
}

export default CardSh
