import React from "react"

import { ABLines, SvgWrapper, Wall } from "../common"
import { TypeHidden } from "../common/types"

import { SizeAH, SizeB } from "../common/sizes"

function ABHiddenNoled(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g>
        <g
          id="main"
          transform="matrix(6.12323e-17,1,-0.877778,5.37484e-17,1022.78,45)"
        >
          <path
            d="M300,100L300,367.722L394.187,367.722L394.187,732.278L300,732.278L300,1000L800,1000L800,100L300,100Z"
            style={{
              fill: "rgb(217,217,217)",
              stroke: "black",
              strokeWidth: "6.38px",
            }}
          />
        </g>
        <ABLines />
      </g>

      <Wall />
      <TypeHidden />

      <SizeAH>← {a} мм →</SizeAH>
      <SizeB>← {b} мм →</SizeB>
    </SvgWrapper>
  )
}

export default ABHiddenNoled
