import React from "react"

function BALedBottom() {
  return (
    <g id="LED" transform="matrix(6.12323e-17,1,-1,6.12323e-17,1135,553)">
      <g transform="matrix(5.51091e-17,0.9,-1.25,7.65404e-17,563,100)">
        <path
          d="M700,150C700,144.481 693.777,140 686.111,140L413.889,140C406.223,140 400,144.481 400,150L400,170C400,175.519 406.223,180 413.889,180L686.111,180C693.777,180 700,175.519 700,170L700,150Z"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.84px" }}
        />
      </g>
      <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,289,1050)">
        <text
          x="411.265px"
          y="92.249px"
          style={{ fontFamily: "'Consolas', monospace", fontSize: "45px" }}
        >
          L
          <tspan x="441.255px 471.245px " y="92.249px 92.249px ">
            ED
          </tspan>
        </text>
      </g>
      <g transform="matrix(1.15661e-16,-1.88889,1.88889,1.15661e-16,340.333,509.667)">
        <path
          d="M9.663,17L14.336,17M12,3L12,4M18.364,5.636L17.657,6.343M21,12L20,12M4,12L3,12M6.343,6.343L5.636,5.636M8.464,15.536C7.527,14.599 7,13.326 7,12.001C7,9.258 9.257,7.001 12,7.001C14.743,7.001 17,9.258 17,12.001C17,13.326 16.473,14.599 15.536,15.536L14.988,16.083C14.356,16.716 14,17.574 14,18.469L14,19C14,20.097 13.097,21 12,21C10.903,21 10,20.097 10,19L10,18.469C10,17.574 9.644,16.715 9.012,16.083L8.464,15.536Z"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "black",
            strokeWidth: "2px",
            strokeMiterlimit: "4",
          }}
        />
      </g>
      <g transform="matrix(1.15661e-16,-1.88889,1.88889,1.15661e-16,340.333,722.667)">
        <path
          d="M9.663,17L14.336,17M12,3L12,4M18.364,5.636L17.657,6.343M21,12L20,12M4,12L3,12M6.343,6.343L5.636,5.636M8.464,15.536C7.527,14.599 7,13.326 7,12.001C7,9.258 9.257,7.001 12,7.001C14.743,7.001 17,9.258 17,12.001C17,13.326 16.473,14.599 15.536,15.536L14.988,16.083C14.356,16.716 14,17.574 14,18.469L14,19C14,20.097 13.097,21 12,21C10.903,21 10,20.097 10,19L10,18.469C10,17.574 9.644,16.715 9.012,16.083L8.464,15.536Z"
          style={{
            fill: "none",
            fillRule: "nonzero",
            stroke: "black",
            strokeWidth: "2px",
            strokeMiterlimit: "4",
          }}
        />
      </g>
    </g>
  )
}

export default BALedBottom
