import React from "react"

function FrVertical() {
  return (
    <g
      id="Fr-V"
      serifId="Fr V"
      transform="matrix(1.61156,0,0,1.17647,-290.602,57.6471)"
    >
      <g transform="matrix(1,0,0,1,13.6552,-2)">
        <path
          d="M540,437.448L540,790.129"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,4.3289,-2)">
        <path
          d="M540,437.448L540,789.825"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,59.1023,-2)">
        <path
          d="M540,437.448L540,789.825"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,49.7897,-2.85)">
        <path
          d="M540,438.298L540,791.1"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,104.549,-2)">
        <path
          d="M540,437.448L540,790.129"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,95.2504,-2)">
        <path
          d="M540,437.448L540,790.129"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,150,5)">
        <path
          d="M540,430.5L540,783.129"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,140.708,4.15)">
        <path
          d="M540,431.298L540,783.25"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1041.97,1190)">
        <path
          d="M540,402.175L540,754.552"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1051.31,1190)">
        <path
          d="M540,402.175L540,754.552"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,996.527,1185)">
        <path
          d="M540,397.175L540,749.552"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1005.85,1185)">
        <path
          d="M540,396.871L540,749.552"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,950.791,1185)">
        <path
          d="M539.5,397.175L539.924,749.325"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,960.719,1185)">
        <path
          d="M539.5,396.75L539.981,749.325"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,905.625,1185)">
        <path
          d="M540,397.175L540,749.325"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,914.933,1185)">
        <path
          d="M540,397.175L540,749.325"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
    </g>
  )
}

export default FrVertical
