import React from "react"

import { AALines, SvgWrapper, Wall } from "../common"
import { TypeUp } from "../common/types"

import { SizeA, SizeB } from "../common/sizes"
import { BALedBottom } from "../common/leds"

function AAUpLedBottom(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g
        id="main"
        transform="matrix(9.67471e-17,1.58,-0.877778,5.37484e-17,1022.78,-274)"
      >
        <path
          d="M300,100L300,1000L800,1000L800,720.886L730.38,720.886L730.38,379.114L800,379.114L800,100L300,100Z"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>

      <AALines />

      <Wall />
      <TypeUp />

      <SizeA>← {a} мм →</SizeA>
      <SizeB>← {b} мм →</SizeB>

      <BALedBottom />
    </SvgWrapper>
  )
}

export default AAUpLedBottom
