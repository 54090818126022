import React, { useState } from "react"
import { useCart } from "react-use-cart"
import {
  PlusIcon as PlusIconMini,
  MinusIcon as MinusIconMini,
} from "@heroicons/react/20/solid"

function EditCard({ item, setEdited }) {
  const { updateItem, updateItemQuantity } = useCart()

  const id = item.id
  const [dNum, setDNum] = useState(item.dNum)

  const [a, setA] = useState(item.a)
  const [b, setB] = useState(item.b)
  const [h, setH] = useState(item.h)

  const [comment, setComment] = useState(item.comment)

  return (
    <>
      <div className="mx-auto w-full">
        <div className="flex max-w-xl items-center justify-between pb-1">
          <p className="text-xs">{item.name}</p>
          <label className="text-xs">
            Договор №:
            <input
              className="ml-1 w-24 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
              placeholder="Договор"
              value={dNum}
              onChange={e => setDNum(e.target.value)}
            />
          </label>
          <p className="text-xs">ТЕХ ЗАДАНИЕ</p>
        </div>

        <div className="grid grid-cols-2 border-t border-gray-300 pt-1">
          <div className="space-y-1">
            {item.name === "МДФ панели с фрезеровкой" ||
            item.name === "Шпонированные панели" ? (
              <>
                <div className="text-xs">
                  <label>
                    Высота:
                    <input
                      className="ml-1 w-12 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
                      placeholder="h"
                      value={h}
                      onChange={e => setH(e.target.value)}
                    />
                  </label>{" "}
                  мм
                </div>
                <div className="text-xs">
                  <label>
                    Ширина:
                    <input
                      className="ml-1 w-12 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
                      placeholder="a"
                      value={a}
                      onChange={e => setA(e.target.value)}
                    />
                  </label>{" "}
                  мм
                </div>
                <div className="text-xs">
                  <label>
                    Толщина:
                    <input
                      className="ml-1 w-12 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
                      placeholder="b"
                      value={b}
                      onChange={e => setB(e.target.value)}
                    />
                  </label>{" "}
                  мм
                </div>
              </>
            ) : (
              <>
                <div className="text-xs">
                  Сечение: (a x b):{" "}
                  <label>
                    a:
                    <input
                      className="ml-1 w-12 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
                      placeholder="a"
                      value={a}
                      onChange={e => setA(e.target.value)}
                    />
                  </label>{" "}
                  x{" "}
                  <label>
                    b:
                    <input
                      className="ml-1 w-12 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
                      placeholder="b"
                      value={b}
                      onChange={e => setB(e.target.value)}
                    />
                  </label>{" "}
                  мм,
                </div>
                <div className="text-xs">
                  <label>
                    Длина:
                    <input
                      className="ml-1 w-12 rounded-md border border-gray-300 bg-white px-1 text-xs hover:bg-gray-100"
                      placeholder="h"
                      value={h}
                      onChange={e => setH(e.target.value)}
                    />
                  </label>{" "}
                  мм
                </div>
              </>
            )}

            <p className="text-xs">
              Количество:{" "}
              <button
                type="button"
                onClick={() =>
                  updateItemQuantity(
                    item.id,
                    item.quantity > 1 ? item.quantity - 1 : item.quantity
                  )
                }
                className="inline-flex items-center rounded-full border border-transparent bg-gray-100 p-0.5 text-gray-900 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <MinusIconMini className="h-3 w-3" aria-hidden="true" />
              </button>
              <span className="px-1 font-semibold">{item.quantity} шт.</span>
              <button
                type="button"
                onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                className="inline-flex items-center rounded-full border border-transparent bg-gray-100 p-0.5 text-gray-900 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <PlusIconMini className="h-3 w-3" aria-hidden="true" />
              </button>
            </p>

            <div className="text-xs">
              Покрытие:{" "}
              <span className="font-semibold">{item?.cover?.name}</span>
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-xs">
              Крепление:{" "}
              <span className="font-semibold">
                {item?.selected?.label || "Клеевое"}
              </span>
            </div>

            {item.name === "МДФ панели с фрезеровкой" ||
            item.name === "Шпонированные панели" ? (
              <div className="text-xs">
                Кромка:{" "}
                <span className="font-semibold">
                  {item?.top ? "сверху" : ""} {item?.bottom ? "снизу" : ""}{" "}
                  {item?.left ? "слева" : ""} {item?.right ? "справа" : ""}
                  {!item?.top && !item?.bottom && !item?.left && !item?.right
                    ? "нет"
                    : ""}
                </span>
              </div>
            ) : null}

            {item?.shDirection !== undefined ? (
              <div className="text-xs">
                Направление шпона:{" "}
                <span className="font-semibold">{item?.shDirection?.name}</span>
              </div>
            ) : null}
            {item?.frDirection !== undefined ? (
              <span className="text-xs">
                Фрезеровка:{" "}
                <span className="font-semibold">{item?.frDirection?.name}</span>
                , Ширина: <span className="font-semibold">{item?.frA} мм </span>
                , Глубина: <span className="font-semibold">{item?.frB} мм</span>
                , Шаг: <span className="font-semibold">{item?.frH} мм</span>
              </span>
            ) : null}

            {item?.selected2 !== undefined ? (
              <div className="text-xs">
                LED подсветка:{" "}
                <span className="font-semibold">{item?.selected2?.label}</span>
              </div>
            ) : null}

            <div className="text-xs">
              Цвет{item?.colorsNum?.id === 2 ? " 1: " : ": "}
              <span className="font-semibold">
                {item?.color?.name} {item?.colorID}
              </span>
            </div>
            {item?.colorsNum?.id === 2 ? (
              <div className="text-xs">
                Цвет 2:{" "}
                <span className="font-semibold">
                  {item?.color2?.name} {item?.color2ID}
                </span>
              </div>
            ) : null}
          </div>
          <textarea
            className="col-span-2 my-1 rounded-lg border border-gray-300 bg-gray-50 text-xs hover:bg-white"
            placeholder="Комментарии"
            value={comment}
            rows="4"
            onChange={e => setComment(e.target.value)}
          />

          <div className="flex items-start space-x-1 border-t border-gray-300 p-1 print:hidden">
            <button
              onClick={() => {
                updateItem(id, {
                  dNum: dNum,
                  a: a,
                  b: b,
                  h: h,
                  comment: comment,
                })

                setEdited(false)
              }}
              className="rounded-md border border-gray-300 bg-green-100 px-1 text-xs hover:bg-green-200"
            >
              Сохранить изменения
            </button>
            <button
              onClick={() => setEdited(false)}
              className="rounded-md border border-red-300 bg-red-100 px-1 text-xs hover:bg-red-200"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditCard
