import React from "react"

import { AALines, SvgWrapper, Wall } from "../common"
import { TypeUp } from "../common/types"

import { SizeA, SizeB } from "../common/sizes"
import { ABLedRight } from "../common/leds"

function AAUpLedRight(props) {
  const { a, b, n, h } = props
  return (
    <SvgWrapper>
      <g
        id="main"
        transform="matrix(9.67471e-17,1.58,-0.877778,5.37484e-17,1022.78,-274)"
      >
        <path
          d="M300,100L300,1000L800,1000L800,100L645.024,100L644.937,225.316L455.063,225.316L455.063,100L300,100Z"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>

      <AALines />

      <Wall />
      <TypeUp />

      <SizeA>← {a} мм →</SizeA>
      <SizeB>← {b} мм →</SizeB>

      <ABLedRight />
    </SvgWrapper>
  )
}

export default AAUpLedRight
