import React from "react"

function SummaryCard({ item }) {
  return (
    <div className="mx-auto w-full">
      <div className="flex max-w-xl items-center justify-between pb-1">
        <p className="text-xs print:text-sm">{item.name}</p>
        <p className="text-xs print:text-sm">Договор №{item.dNum}</p>
        <p className="text-xs print:text-sm">ТЕХ ЗАДАНИЕ</p>
      </div>

      <div className="grid grid-cols-2 border-t border-gray-300 pt-1">
        <div className="space-y-1">
          {item.name === "МДФ панели с фрезеровкой" ||
          item.name === "Шпонированные панели" ? (
            <>
              <div className="text-xs print:text-sm">
                Высота: <span className="font-semibold">{item.h} мм</span>{" "}
              </div>
              <div className="text-xs print:text-sm">
                Ширина: <span className="font-semibold">{item.a} мм</span>,
              </div>
              <div className="text-xs print:text-sm">
                Толщина: <span className="font-semibold">{item.b} мм</span>
              </div>
            </>
          ) : (
            <>
              <div className="text-xs print:text-sm">
                Сечение: (a x b):{" "}
                <span className="font-semibold">
                  {item.a} мм x {item.b} мм,
                </span>{" "}
              </div>
              <div className="text-xs print:text-sm">
                Длина: <span className="font-semibold">{item.h} мм</span>
              </div>
            </>
          )}

          <p className="text-xs print:text-sm">
            Количество:{" "}
            <span className="font-semibold">{item.quantity} шт.</span>
          </p>

          <div className="text-xs print:text-sm">
            Покрытие: <span className="font-semibold">{item?.cover?.name}</span>
          </div>
        </div>
        <div className="space-y-1">
          <div className="text-xs print:text-sm">
            Крепление:{" "}
            <span className="font-semibold">
              {item?.selected?.label || "Клеевое"}
            </span>
          </div>

          {item.name === "МДФ панели с фрезеровкой" ||
          item.name === "Шпонированные панели" ? (
            <div className="text-xs print:text-sm">
              Кромка:{" "}
              <span className="font-semibold">
                {item?.top ? "сверху" : ""} {item?.bottom ? "снизу" : ""}{" "}
                {item?.left ? "слева" : ""} {item?.right ? "справа" : ""}
                {!item?.top && !item?.bottom && !item?.left && !item?.right
                  ? "нет"
                  : ""}
              </span>
            </div>
          ) : null}

          {item?.shDirection !== undefined ? (
            <div className="text-xs print:text-sm">
              Направление шпона:{" "}
              <span className="font-semibold">{item?.shDirection?.name}</span>
            </div>
          ) : null}
          {item?.frDirection !== undefined ? (
            <span className="text-xs print:text-sm">
              Фрезеровка:{" "}
              <span className="font-semibold">{item?.frDirection?.name}</span>,
              Ширина: <span className="font-semibold">{item?.frA} мм </span>,
              Глубина: <span className="font-semibold">{item?.frB} мм</span>,
              Шаг: <span className="font-semibold">{item?.frH} мм</span>
            </span>
          ) : null}

          {item?.selected2 !== undefined ? (
            <div className="text-xs print:text-sm">
              LED подсветка:{" "}
              <span className="font-semibold">{item?.selected2?.label}</span>
            </div>
          ) : null}

          <div className="text-xs print:text-sm">
            Цвет{item?.colorsNum?.id === 2 ? " 1: " : ": "}
            <span className="font-semibold">
              {item?.color?.name} {item?.colorID}
            </span>
          </div>
          {item?.colorsNum?.id === 2 ? (
            <div className="text-xs print:text-sm">
              Цвет 2:{" "}
              <span className="font-semibold">
                {item?.color2?.name} {item?.color2ID}
              </span>
            </div>
          ) : null}
        </div>

        <div className="col-span-2 mt-2 h-20 rounded-lg border border-gray-300 bg-gray-50">
          <div className="px-2 pt-2 text-xs text-gray-900 print:text-sm">
            Комментарии: {item.comment}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SummaryCard
