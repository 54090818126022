import React from "react"

function AALines() {
  return (
    <g>
      <g transform="matrix(0.5,0,0,0.482759,1.42109e-14,157.552)">
        <path
          d="M290,90L0,90"
          style={{ fill: "none", stroke: "black", strokeWidth: "2.03px" }}
        />
      </g>
      <g transform="matrix(0.5,0,0,1,540,110)">
        <path
          d="M790,90L1080,90"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
        />
      </g>
      <g transform="matrix(0.5,0,0,1,540,0)">
        <path
          d="M790,990L1080,990"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
        />
      </g>
      <g transform="matrix(0.5,0,0,1,3.55271e-15,-4.54747e-13)">
        <path
          d="M290,990L0,990"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
        />
      </g>
      <g transform="matrix(0.382979,0,0,1,33.9362,-3.52429e-12)">
        <path
          d="M290,990L290,1080"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.32px" }}
        />
      </g>
      <g transform="matrix(0.382979,0,0,1,632.447,-4.54747e-13)">
        <path
          d="M790,990L790,1080"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.32px" }}
        />
      </g>
      <g transform="matrix(0.261538,0,0,0.566667,728.385,150)">
        <path
          d="M790,90L790,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2.27px" }}
        />
      </g>
      <g transform="matrix(0.261538,0,0,0.566667,69.1538,150)">
        <path
          d="M290,90L290,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2.27px" }}
        />
      </g>
    </g>
  )
}

export default AALines
