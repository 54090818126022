export function getDate() {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  return dd + "." + mm + "." + yyyy
}

export function getENDate() {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  return yyyy + "-" + mm + "-" + dd
}

export function getId() {
  const today = new Date()
  const hh = String(today.getHours()).padStart(2, "0")
  const mi = String(today.getMinutes()).padStart(2, "0")
  const ms = String(today.getMilliseconds()).padStart(2, "0")
  return hh + "-" + mi + ms
}

export const isBrowser = () => typeof window !== "undefined"
