import React from "react"

import { BALines, SvgWrapper, Wall } from "../common"
import { TypeGlue } from "../common/types"

import { SizeA, SizeBV } from "../common/sizes"
import { BALedBottom } from "../common/leds"

function BAGlueLedBottom(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g id="main" transform="matrix(1,0,0,0.877778,-10,112.222)">
        <path
          d="M300,100L300,1000L399.479,1000L399.479,874.684L700.521,874.684L700.521,1000L800,1000L800,100L300,100Z"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>
      <BALines />

      <Wall />
      <TypeGlue />

      <SizeA>← {a} мм →</SizeA>
      <SizeBV> ← {b} мм →</SizeBV>

      <BALedBottom />
    </SvgWrapper>
  )
}

export default BAGlueLedBottom
