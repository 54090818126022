import React, { useState } from "react"

import { PencilIcon, XCircleIcon } from "@heroicons/react/24/outline"

import { useCart } from "react-use-cart"

import CardR from "./CardR"
import EditCard from "./EditCard"
import SummaryCard from "./SummaryCard"
import CardKor from "./CardKor"
import CardSh from "./CardSh"
import CardP from "./CardP"
import CardFr from "./CardFr"

function Item({ children, item }) {
  const { removeItem } = useCart()
  const [isEdited, setEdited] = useState(false)

  return (
    <li
      key={item.id}
      className="break-inside-avoid rounded-md border border-gray-300 bg-white p-2"
    >
      <div className="flex pb-1">
        <div className="grow space-y-1">
          {isEdited ? (
            <EditCard item={item} setEdited={setEdited} />
          ) : (
            <>
              <SummaryCard item={item} />

              <div className="flex items-start space-x-1 border-t border-gray-300 p-1 print:hidden">
                <button
                  type="button"
                  onClick={() => setEdited(true)}
                  className="inline-flex items-center pt-[0.5px] text-xs text-gray-700 hover:text-red-900"
                >
                  <PencilIcon className="mr-1 h-4 w-4" aria-hidden="true" />
                  Редактировать
                </button>
                <button
                  type="button"
                  onClick={() => removeItem(item.id)}
                  className="inline-flex items-center text-xs text-red-700 hover:text-red-900"
                >
                  <XCircleIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                  Удалить
                </button>
              </div>
            </>
          )}
        </div>
        <div className="flex-none pl-2">
          {item.name === "Стеновые и потолочные рейки" ? (
            <CardR item={item} />
          ) : item.name === "Реечные перегородки" ? (
            <CardP item={item} />
          ) : item.name === "Шпонированные панели" ? (
            <CardSh item={item} />
          ) : item.name === "МДФ панели с фрезеровкой" ? (
            <CardFr item={item} />
          ) : item.name === "Рейка короб" ? (
            <CardKor item={item} />
          ) : null}
        </div>
      </div>

      {children}
    </li>
  )
}

export default Item
