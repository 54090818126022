import React from "react"
import AAKor from "../Forms/2D/Kor/AAKor"

import ABKor from "../Forms/2D/Kor/ABKor"
import BAKor from "../Forms/2D/Kor/BAKor"

function CardKor({ item }) {
  return (
    <div className="h-56">
      {item.b > item.a ? (
        <BAKor a={item.a} b={item.b} />
      ) : item.b < item.a ? (
        <ABKor a={item.a} b={item.b} />
      ) : (
        <AAKor a={item.a} b={item.b} />
      )}
    </div>
  )
}

export default CardKor
