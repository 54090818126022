import React from "react"

import { AAP, ABP, BAP } from "../Forms/2D/P"

function CardP({ item }) {
  return (
    <div className="h-56">
      {item.b > item.a ? <BAP a={item.a} b={item.b} /> : null}

      {item.b < item.a ? <ABP a={item.a} b={item.b} /> : null}

      {item.b === item.a ? <AAP a={item.a} b={item.b} /> : null}
    </div>
  )
}

export default CardP
