import React from "react"

function Glue() {
  return (
    <g>
      <path
        d="M780,62.5C780,55.601 774.399,50 767.5,50L312.5,50C305.601,50 300,55.601 300,62.5L300,87.5C300,94.399 305.601,100 312.5,100L767.5,100C774.399,100 780,94.399 780,87.5L780,62.5Z"
        style={{
          fill: "rgb(217,217,217)",
          stroke: "black",
          strokeWidth: "2px",
        }}
      />
      <text
        x="436.284px"
        y="92.249px"
        style={{ fontFamily: "'Consolas', monospace", fontSize: "45px" }}
      >
        К
        <tspan
          x="466.274px 496.265px 526.255px 556.245px 586.235px 616.226px "
          y="92.249px 92.249px 92.249px 92.249px 92.249px 92.249px "
        >
          ЛЕЕВОЕ
        </tspan>
      </text>
    </g>
  )
}

export default Glue
