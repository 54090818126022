import React from "react"

import {
  AAGlueLedBottom,
  AAGlueLedLeft,
  AAGlueLedRight,
  AAGlueNoled,
  ABGlueLedBottom,
  ABGlueLedLeft,
  ABGlueLedRight,
  ABGlueNoled,
  BAGlueLedBottom,
  BAGlueLedLeft,
  BAGlueLedRight,
  BAGlueNoled,
} from "../Forms/2D/Glue"

import {
  AAHiddenLedBottom,
  AAHiddenLedLeft,
  AAHiddenLedRight,
  AAHiddenNoled,
  ABHiddenLedBottom,
  ABHiddenLedLeft,
  ABHiddenLedRight,
  ABHiddenNoled,
  BAHiddenLedBottom,
  BAHiddenLedLeft,
  BAHiddenLedRight,
  BAHiddenNoled,
} from "../Forms/2D/Hidden"

import {
  AAUpLedBottom,
  AAUpLedLeft,
  AAUpLedRight,
  AAUpNoled,
  ABUpLedBottom,
  ABUpLedLeft,
  ABUpLedRight,
  ABUpNoled,
  BAUpLedBottom,
  BAUpLedLeft,
  BAUpLedRight,
  BAUpNoled,
} from "../Forms/2D/Up"

function CardR({ item }) {
  if (item?.selected?.value === "glue" && item?.selected2?.value === "noled")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAGlueNoled a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABGlueNoled a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAGlueNoled a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "glue" && item?.selected2?.value === "left")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAGlueLedLeft a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABGlueLedLeft a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAGlueLedLeft a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "glue" && item?.selected2?.value === "bottom")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAGlueLedBottom a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABGlueLedBottom a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAGlueLedBottom a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "glue" && item?.selected2?.value === "right")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAGlueLedRight a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABGlueLedRight a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAGlueLedRight a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "hidden" && item?.selected2?.value === "noled")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAHiddenNoled a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABHiddenNoled a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAHiddenNoled a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "hidden" && item?.selected2?.value === "left")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAHiddenLedLeft a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABHiddenLedLeft a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAHiddenLedLeft a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "hidden" && item?.selected2?.value === "bottom")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAHiddenLedBottom a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABHiddenLedBottom a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAHiddenLedBottom a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "hidden" && item?.selected2?.value === "right")
    return (
      <div className="h-56">
        {item.b > item.a ? <BAHiddenLedRight a={item.a} b={item.b} /> : null}

        {item.b < item.a ? <ABHiddenLedRight a={item.a} b={item.b} /> : null}

        {item.b === item.a ? <AAHiddenLedRight a={item.a} b={item.b} /> : null}
      </div>
    )

  if (item?.selected?.value === "up")
    return (
      <div className="h-56">
        {item.b > item.a && item?.selected2?.value === "noled" ? (
          <BAUpNoled a={item.a} b={item.b} />
        ) : null}

        {item.b > item.a && item?.selected2?.value === "left" ? (
          <BAUpLedLeft a={item.a} b={item.b} />
        ) : null}

        {item.b > item.a && item?.selected2?.value === "bottom" ? (
          <BAUpLedBottom a={item.a} b={item.b} />
        ) : null}

        {item.b > item.a && item?.selected2?.value === "right" ? (
          <BAUpLedRight a={item.a} b={item.b} />
        ) : null}

        {item.b < item.a && item?.selected2?.value === "right" ? (
          <ABUpLedRight a={item.a} b={item.b} />
        ) : null}

        {item.b < item.a && item?.selected2?.value === "noled" ? (
          <ABUpNoled a={item.a} b={item.b} />
        ) : null}

        {item.b < item.a && item?.selected2?.value === "left" ? (
          <ABUpLedLeft a={item.a} b={item.b} />
        ) : null}

        {item.b < item.a && item?.selected2?.value === "bottom" ? (
          <ABUpLedBottom a={item.a} b={item.b} />
        ) : null}

        {item.b === item.a && item?.selected2?.value === "noled" ? (
          <AAUpNoled a={item.a} b={item.b} />
        ) : null}

        {item.b === item.a && item?.selected2?.value === "left" ? (
          <AAUpLedLeft a={item.a} b={item.b} />
        ) : null}

        {item.b === item.a && item?.selected2?.value === "bottom" ? (
          <AAUpLedBottom a={item.a} b={item.b} />
        ) : null}

        {item.b === item.a && item?.selected2?.value === "right" ? (
          <AAUpLedRight a={item.a} b={item.b} />
        ) : null}
      </div>
    )

  return <p className="text-xs text-red-500">Неизвестный тип</p>
}

export default CardR
