import React from "react"

function Checklist() {
  return (
    <div className="mt-1 hidden border-t border-gray-300 py-1 print:block">
      <h3 className="text-center text-sm font-semibold">
        Чек-лист проверки заказа
      </h3>
      <div className="grid grid-cols-3 gap-x-4 gap-y-0.5 pt-0.5 print:gap-x-1">
        <div className="col-span-2 mb-4 flex justify-between border-2 border-dotted border-gray-500">
          <span className="pl-1 text-sm">ФИО ответственного</span>
          <div className="w-72 ring-2 ring-gray-500"></div>
        </div>

        <div className="mb-4 flex justify-between border-2 border-dotted border-gray-500">
          <span className="pl-1 text-sm">Дата</span>
          <div className="w-40 ring-2 ring-gray-500"></div>
        </div>

        <div className="flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Толщина</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>

        <div className="flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Длина</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>

        <div className=" flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Залом шпона по краям</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>

        <div className="flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Ширина</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>

        <div className="flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Угол 90°</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>

        <div className="flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Количество</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>

        <div />
        <div />
        <div className="flex justify-between border border-gray-500">
          <span className="pl-1 text-sm">Покраска</span>
          <div className=" w-8 border border-l-2 border-gray-500"></div>
        </div>
      </div>
    </div>
  )
}

export default Checklist
