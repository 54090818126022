import React from "react"

function Fr45() {
  return (
    <g
      id="Fr-45"
      serifId="Fr 45"
      transform="matrix(1.13955,1.13955,-1.09175,1.09175,600.742,-500.305)"
    >
      <g transform="matrix(1,0,0,1,13.6552,-2)">
        <path
          d="M540,404.464L540,790.129"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,4.3289,-2)">
        <path
          d="M540,414.199L540,794.192"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,59.1023,-2)">
        <path
          d="M540,413.107L540,789.825"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,49.7897,-2.85)">
        <path
          d="M540,404.236L540,791.1"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,104.549,-2)">
        <path
          d="M540,461.817L540,689.584"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,95.2504,-2)">
        <path
          d="M540,452.111L540,699.29"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,150,5)">
        <path
          d="M540,502.258L540,635.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,140.708,4.15)">
        <path
          d="M540,494.208L540,645.692"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,2.22045e-16,1.11022e-16,1,193.182,5)">
        <path
          d="M540,547.33L540,590.071"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,2.22045e-16,1.11022e-16,1,183.889,4.15)">
        <path
          d="M540,538.481L540,600.62"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1041.97,1190)">
        <path
          d="M540,379.59L540,733.592"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1051.31,1190)">
        <path
          d="M540,369.522L540,743.334"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,996.527,1185)">
        <path
          d="M540,397.175L540,681.155"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1005.85,1185)">
        <path
          d="M540,396.871L540,690.883"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,950.791,1185)">
        <path
          d="M539.5,469.066L539.785,633.642"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,960.719,1185)">
        <path
          d="M539.5,458.881L539.981,643.8"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-4.44089e-16,1.11022e-16,-1,905.242,1185)">
        <path
          d="M539.5,516.786L539.924,585.954"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(-1,-4.44089e-16,1.11022e-16,-1,915.171,1185)">
        <path
          d="M539.5,505.163L539.68,596.571"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
    </g>
  )
}

export default Fr45
