import React from "react"
import Cover45 from "./Cover45"
import CoverHorizontal from "./CoverHorizontal"
import CoverVertical from "./CoverVertical"
import Fr45 from "./Fr45"
import FrVertical from "./FrVertical"

function AAFr({ item }) {
  const { top, bottom, left, right, shDirection, frDirection } = item
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsSerif="http://www.serif.com/"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: "1.5",
      }}
    >
      {shDirection?.name === "верт" ? (
        <CoverVertical />
      ) : shDirection?.name === "гориз" ? (
        <CoverHorizontal />
      ) : (
        <Cover45 />
      )}

      {frDirection?.name === "верт" ? <FrVertical /> : <Fr45 />}

      <g id="corners" transform="matrix(1.75714,0,0,2.85714,-652.429,-1160)">
        <path
          d="M515,420L535,440L845,440L865,420L515,420Z"
          style={
            top
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
        <path
          d="M865,420L865,770L845,750L845,440L865,420Z"
          style={
            right
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
        <path
          d="M535,440L515,420L515,770L535,750L535,440Z"
          style={
            left
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
        <path
          d="M535,750L845,750L865,770L515,770L535,750Z"
          style={
            bottom
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
      </g>
    </svg>
  )
}

export default AAFr
