import React from "react"

function CoverHorizontal() {
  return (
    <g id="Half-Cover-Hor" serifId="Half Cover Hor">
      <g transform="matrix(1,0,0,1,-2.35714,-20)">
        <path
          d="M287.643,200L832.357,200"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,2.35714,-40)">
        <path
          d="M287.643,300L832.357,300"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,0,-60)">
        <path
          d="M287.643,400L832.357,400"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,0,-80)">
        <path
          d="M287.643,500L832.357,500"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,2.35714,-100)">
        <path
          d="M287.643,600L832.357,600"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
    </g>
  )
}

export default CoverHorizontal
