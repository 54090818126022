import React from "react"
import Cover45 from "./Cover45"
import CoverHorizontal from "./CoverHorizontal"
import CoverVertical from "./CoverVertical"

function AASh({ item }) {
  const { top, bottom, left, right, shDirection } = item
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsSerif="http://www.serif.com/"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: "1.5",
      }}
    >
      {shDirection?.name === "верт" ? (
        <CoverVertical />
      ) : shDirection?.name === "гориз" ? (
        <CoverHorizontal />
      ) : (
        <Cover45 />
      )}

      {/* <g id="Фрез" transform="matrix(1.61156,0,0,1.17647,-290.602,57.6471)">
        <g transform="matrix(1,0,0,1,-11.3448,0)">
          <g transform="matrix(1,0,0,1,25,-2)">
            <path
              d="M540,444.5L540,788.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,18.7763,-2)">
            <path
              d="M540,444.5L540,788.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-15.8977,0)">
          <g transform="matrix(1,0,0,1,75,-2)">
            <path
              d="M540,461.5L540,788.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,68.7899,-2)">
            <path
              d="M540,461.5L540,788.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-20.4505,0)">
          <g transform="matrix(1,0,0,1,125,-2)">
            <path
              d="M540,506L540,788.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,118.804,-2)">
            <path
              d="M540,506L540,788.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g>
          <g transform="matrix(1,0,0,1,150,5)">
            <path
              d="M540,551L540,781.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,143.81,5)">
            <path
              d="M540,551L540,781.429"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-13.6509,0)">
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1055.62,1190)">
            <path
              d="M540,403.571L540,750"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1061.86,1190)">
            <path
              d="M540,403.571L540,750"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-9.09799,0)">
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1005.62,1185)">
            <path
              d="M540,398.571L540,736"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,1011.84,1185)">
            <path
              d="M540,398.571L540,736"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-4.83421,0)">
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,955.625,1185)">
            <path
              d="M539.5,398.571L539.924,707.5"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,961.83,1185)">
            <path
              d="M539.5,398.571L539.924,707.5"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
        <g>
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,905.625,1185)">
            <path
              d="M540,398.571L540,629"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
          <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,911.83,1185)">
            <path
              d="M540,398.571L540,629"
              style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
            />
          </g>
        </g>
      </g> */}
      <g id="corners" transform="matrix(1.75714,0,0,2.85714,-652.429,-1160)">
        <path
          d="M515,420L535,440L845,440L865,420L515,420Z"
          style={
            top
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
        <path
          d="M865,420L865,770L845,750L845,440L865,420Z"
          style={
            right
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
        <path
          d="M535,440L515,420L515,770L535,750L535,440Z"
          style={
            left
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
        <path
          d="M535,750L845,750L865,770L515,770L535,750Z"
          style={
            bottom
              ? { stroke: "black", strokeWidth: "0.84px" }
              : { fill: "white", stroke: "black", strokeWidth: "0.84px" }
          }
        />
      </g>
    </svg>
  )
}

export default AASh
