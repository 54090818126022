import React from "react"

function Cover45() {
  return (
    <g
      id="Half-Cover-45"
      serifId="Half Cover 45"
      transform="matrix(1.61156,1.11022e-16,-1.11022e-16,1.17647,-289.81,-393.78)"
    >
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.818,212.182L402.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.818,312.182L502.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.643,412.357L602.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M287.643,512.357L702.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M373.868,526.132L802.857,97.143"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M473.868,526.132L832.123,167.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M573.868,526.132L832.123,267.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M673.868,526.132L832.123,367.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
      <g transform="matrix(0.620516,0,0,0.85,180.323,321)">
        <path
          d="M773.868,526.132L832.123,467.877"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.42px" }}
        />
      </g>
    </g>
  )
}

export default Cover45
