import React from "react"

import { ABLines, SvgWrapper, Wall } from "../common"
import { TypeUp } from "../common/types"

import { SizeAH, SizeB } from "../common/sizes"
import { ABLedBottom } from "../common/leds"

function ABUpLedBottom(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g
        id="main"
        transform="matrix(6.12323e-17,1,-0.877778,5.37484e-17,1022.78,45)"
      >
        <path
          d="M300,100L300,1000L800,1000L800,720.137L690,720.137L690,378.661L800,378.661L800,100L300,100Z"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>
      <ABLines />

      <Wall />
      <TypeUp />

      <SizeAH>← {a} мм →</SizeAH>
      <SizeB>← {b} мм →</SizeB>

      <ABLedBottom />
    </SvgWrapper>
  )
}

export default ABUpLedBottom
