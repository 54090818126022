import React from "react"

import { BALines, SvgWrapper, Wall } from "../common"
import { TypeUp } from "../common/types"
import { SizeA, SizeBV } from "../common/sizes"
import { BALedRight } from "../common/leds"

function BAUpLedRight(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g id="main" transform="matrix(1,0,0,0.877778,-10,112.222)">
        <path
          d="M300,100L300,1000L800,1000L800,720.886L690,720.886L690,377.961L800,377.961L800,100L300,100Z"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>
      <BALines />

      <Wall />
      <TypeUp />

      <SizeA>← {a} мм →</SizeA>
      <SizeBV>← {b} мм →</SizeBV>

      <BALedRight />
    </SvgWrapper>
  )
}

export default BAUpLedRight
