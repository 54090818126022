import React from "react"

import { ABLines, SvgWrapper, Wall } from "../common"

import { SizeA, SizeB } from "../common/sizes"

function ABKor(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g>
        <g id="main" transform="matrix(6.12323e-17,1,-1,6.12323e-17,1135,55)">
          <path
            d="M290,200L290,990L790,990L790,200L290,200ZM370,280L370,910L710,910L710,280L370,280Z"
            style={{
              fill: "rgb(217,217,217)",
              stroke: "black",
              strokeWidth: "6.38px",
            }}
          />
        </g>

        <ABLines />
      </g>

      <Wall />

      <SizeA>← {a} мм →</SizeA>
      <SizeB>← {b} мм →</SizeB>
    </SvgWrapper>
  )
}

export default ABKor
