import React from "react"
import { graphql } from "gatsby"

import Cart from "../components/Cart"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CartPage = ({ data }) => {
  // if (isEmpty) return <p>Список заказов пуст</p>

  return (
    <Layout>
      <SEO title="Список заказов" />
      <Cart data={data} />
    </Layout>
  )
}

export const query = graphql`
  query PPageQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 120, placeholder: BLURRED, formats: PNG)
          }
        }
      }
    }
  }
`

export default CartPage
