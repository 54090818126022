import React from "react"

import { BALines, SvgWrapper, Wall } from "../common"
import { TypeGlue } from "../common/types"

import { SizeA, SizeBV } from "../common/sizes"

function BAGlueNoled(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g id="main" transform="matrix(1,0,0,0.877778,-10,112.222)">
        <rect
          x="300"
          y="100"
          width="500"
          height="900"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>
      <BALines />

      <Wall />
      <TypeGlue />

      <SizeA>← {a} мм →</SizeA>
      <SizeBV>← {b} мм →</SizeBV>
    </SvgWrapper>
  )
}

export default BAGlueNoled
