import React from "react"

import { AALines, SvgWrapper, Wall } from "../common"
import { TypeGlue } from "../common/types"

import { SizeA, SizeB } from "../common/sizes"

function AAGlueNoled(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g
        id="main"
        transform="matrix(9.67471e-17,1.58,-0.877778,5.37484e-17,1022.78,-274)"
      >
        <rect
          x="300"
          y="100"
          width="500"
          height="900"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>
      <AALines />

      <Wall />
      <TypeGlue />

      <SizeA>← {a} мм →</SizeA>
      <SizeB>← {b} мм →</SizeB>
    </SvgWrapper>
  )
}

export default AAGlueNoled
