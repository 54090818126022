import React from "react"

function ABLines() {
  return (
    <>
      <g transform="matrix(0.5,0,0,0.482759,1.42109e-14,301.552)">
        <path
          d="M290,90L0,90"
          style={{ fill: "none", stroke: "black", strokeWidth: "2.03px" }}
        />
      </g>
      <g transform="matrix(0.5,0,0,1,540,255)">
        <path
          d="M790,90L1080,90"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
        />
      </g>
      <g transform="matrix(0.5,0,0,1,540,-145)">
        <path
          d="M790,990L1080,990"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
        />
      </g>
      <g transform="matrix(0.5,0,0,1,3.55271e-15,-145)">
        <path
          d="M290,990L0,990"
          style={{ fill: "none", stroke: "black", strokeWidth: "1.26px" }}
        />
      </g>
      <g transform="matrix(1,0,0,2.61111,-145,-1740)">
        <path
          d="M290,990L290,1080"
          style={{ fill: "none", stroke: "black", strokeWidth: "0.51px" }}
        />
      </g>
      <g transform="matrix(1,0,0,2.61111,145,-1740)">
        <path
          d="M790,990L790,1080"
          style={{ fill: "none", stroke: "black", strokeWidth: "0.51px" }}
        />
      </g>
      <g transform="matrix(1,0,0,2.16667,145,150)">
        <path
          d="M790,90L790,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "0.59px" }}
        />
      </g>
      <g transform="matrix(1,0,0,2.16667,-145,150)">
        <path
          d="M290,90L290,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "0.59px" }}
        />
      </g>
    </>
  )
}

export default ABLines
