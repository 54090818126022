import React from "react"

function Wall() {
  return (
    <>
      <path
        d="M0,40L1080,40M1080,0L0,0M0,40L0,0M1080,0L1080,40"
        style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
      />
      <g>
        <path
          d="M0,40L40,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M40,40L80,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M80,40L120,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M120,40L160,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M160,40L200,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M200,40L240,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M240,40L280,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M280,40L320,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M320,40L360,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M360,40L400,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M400,40L440,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M440,40L480,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M480,40.085L520.085,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M520.085,40L560.085,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M560,40L600,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M600,39.759L639.759,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M640,39.759L679.759,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M680,39.759L720,-0.241"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M720,39.759L759.759,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M760,39.759L799.759,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M800,39.759L840,-0.241"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M840,40L880,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M880,40L920,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M920,39.759L959.759,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M960,40L1000,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M1000,39.759L1039.76,-0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
        <path
          d="M1039.76,40L1079.76,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "2px" }}
        />
      </g>
    </>
  )
}

export default Wall
