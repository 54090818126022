import React from "react"
import { Transition } from "@headlessui/react"

function Wrapper({ children }) {
  return (
    <div className="space-y-2 rounded-lg bg-gray-100 px-2 py-2 shadow sm:px-4">
      <Transition
        appear={true}
        show={true}
        enter="transition-opacity duration-700"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-700"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </div>
  )
}

export default Wrapper
