import React from "react"
import AAFr from "../Forms/2D/Fr/AAFr"

function CardFr({ item }) {
  return (
    <div className="h-56">
      <AAFr item={item} />
    </div>
  )
}

export default CardFr
