import React from "react"

function BALines() {
  return (
    <>
      <g transform="matrix(1,0,0,1,0,110)">
        <path
          d="M290,90L0,90"
          style={{ fill: "none", stroke: "black", strokeWidth: "1px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1,0,110)">
        <path
          d="M790,90L1080,90"
          style={{ fill: "none", stroke: "black", strokeWidth: "1px" }}
        />
      </g>
      <path
        d="M790,990L1080,990"
        style={{ fill: "none", stroke: "black", strokeWidth: "1px" }}
      />
      <path
        d="M290,990L0,990"
        style={{ fill: "none", stroke: "black", strokeWidth: "1px" }}
      />
      <path
        d="M290,990L290,1080"
        style={{ fill: "none", stroke: "black", strokeWidth: "1px" }}
      />
      <path
        d="M790,990L790,1080"
        style={{ fill: "none", stroke: "black", strokeWidth: "1px" }}
      />
      <g transform="matrix(1,0,0,1.77778,0,40)">
        <path
          d="M790,90L790,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "0.69px" }}
        />
      </g>
      <g transform="matrix(1,0,0,1.77778,0,40)">
        <path
          d="M290,90L290,0"
          style={{ fill: "none", stroke: "black", strokeWidth: "0.69px" }}
        />
      </g>
    </>
  )
}

export default BALines
