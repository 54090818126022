import React, { useRef, useState, useEffect } from "react"
import { useReactToPrint } from "react-to-print"

import { useCart } from "react-use-cart"

import { useRecoilState } from "recoil"
import { userState } from "../../store"

import { useLocalStorage } from "../hooks"

import Wrapper from "../Forms/Wrapper"
import { getENDate } from "../helpers"
import CleanButton from "../UI/CleanButton"
import SaveButton from "../UI/SaveButton"
import Checklist from "./Checklist"
import ChecklistToggle from "./ChecklistToggle"

import Item from "./Item"

function Cart({ data }) {
  const [user, setUser] = useRecoilState(userState)
  const { emptyCart, totalUniqueItems, items } = useCart()

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const [date, setDate] = useState(getENDate())
  const [phone, setPhone] = useLocalStorage("manager_phone", "")

  useEffect(() => {
    if (phone === "" || phone === null || phone === undefined) {
      setPhone(user?.phone)
    }
  }, [user?.phone])

  const [checklistEnabled, setChecklistEnabled] = useState(true)

  return (
    <Wrapper>
      <h1 className="text-lg">
        Список заказов ({totalUniqueItems}) | Wood Creative
      </h1>

      <ul ref={componentRef} className="grid grid-cols-1 gap-2 py-2 print:m-6">
        <div className="flex justify-between border-b border-gray-300 pb-2">
          <div className="space-x-2">
            <label className="text-sm">
              Менеджер:
              <input
                type="text"
                name="name"
                placeholder="Имя менеджера"
                className="ml-1 rounded-lg border border-gray-300 p-1 text-sm"
              />
            </label>
            <label className="text-sm">
              Телефон:
              <input
                value={phone}
                onChange={e => setPhone(e.target.value)}
                type="tel"
                name="phone"
                placeholder="Номер телефона"
                className="ml-1 rounded-lg border border-gray-300 p-1 text-sm"
              />
            </label>
          </div>
          <div>
            <label htmlFor="start" className="text-sm">
              Дата:
            </label>

            <input
              type="date"
              id="start"
              name="com-start"
              value={date}
              onChange={e => setDate(e.target.value)}
              className="ml-2 rounded-lg border border-gray-300 p-1 text-sm"
            />
          </div>
        </div>
        {items.map(item => (
          <Item key={item.id} item={item} data={data}>
            {checklistEnabled ? <Checklist /> : null}
          </Item>
        ))}
      </ul>
      <div className="flex items-center space-x-4">
        <ChecklistToggle
          enabled={checklistEnabled}
          setEnabled={setChecklistEnabled}
        />
        <SaveButton onClick={handlePrint} />
        <CleanButton
          onClick={() => {
            emptyCart()
          }}
        />
      </div>
    </Wrapper>
  )
}

export default Cart
