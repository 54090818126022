import React from "react"

import { ABLines, SvgWrapper, Wall } from "../common"
import { TypeUp } from "../common/types"

import { SizeAH, SizeB } from "../common/sizes"

function ABUpNoled(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g
        id="main"
        transform="matrix(6.12323e-17,1,-0.877778,5.37484e-17,1022.78,45)"
      >
        <rect
          x="300"
          y="100"
          width="500"
          height="900"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>
      <ABLines />

      <Wall />
      <TypeUp />

      <SizeAH>← {a} мм →</SizeAH>
      <SizeB>← {b} мм →</SizeB>
    </SvgWrapper>
  )
}

export default ABUpNoled
