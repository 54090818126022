import React from "react"

function TypeUp() {
  return (
    <g>
      <path
        d="M450,180L400,180L400,40L680,40L680,180L630,180L630,170L670,170L670,50L410,50L410,170L450,170L450,180Z"
        style={{
          fill: "rgb(217,217,217)",
          stroke: "black",
          strokeWidth: "2px",
        }}
      />
      <text
        x="419.665px"
        y="90.524px"
        style={{ fontFamily: "'Consolas', monospace", fontSize: "45px" }}
      >
        П
        <tspan
          x="446.656px 473.647px 500.638px 527.629px 554.621px 581.612px 608.603px 635.594px "
          y="90.524px 90.524px 90.524px 90.524px 90.524px 90.524px 90.524px 90.524px "
        >
          ОДВЕСНОЕ
        </tspan>
      </text>
    </g>
  )
}

export default TypeUp
