import React from "react"

import { AALines, SvgWrapper, Wall } from "../common"

import { SizeA, SizeB } from "../common/sizes"

function AAKor(props) {
  const { a, b } = props
  return (
    <SvgWrapper>
      <g
        id="main"
        transform="matrix(9.67471e-17,1.58,-1,6.12323e-17,1135,-258.2)"
      >
        <path
          d="M290,200L290,990L790,990L790,200L290,200ZM340.633,280L340.633,910L739.367,910L739.367,280L340.633,280Z"
          style={{
            fill: "rgb(217,217,217)",
            stroke: "black",
            strokeWidth: "6.38px",
          }}
        />
      </g>

      <AALines />

      <Wall />

      <SizeA>← {a} мм →</SizeA>
      <SizeB>← {b} мм →</SizeB>
    </SvgWrapper>
  )
}

export default AAKor
